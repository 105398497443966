<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getCategories } from "../../../apis/merchant";
export default {
  mounted() {
    getCategories(this.token).then((data) => {
      this.setCategories(data);
    });
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    ...mapActions({
      setCategories: "merchant/categories/setList",
    }),
  },
};
</script>